import { effectScope, ref, onScopeDispose, onMounted } from "vue";

export const useGlobalEventListenerLIFO = (() => {
  const listenersMap = ref(new Map());
  const scopesMap = ref(new Map());

  const removeListener = (eventName, listener) => {
    const listeners = listenersMap.value.get(eventName);
    if (listeners) {
      const index = listeners.indexOf(listener);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
      if (listeners.length === 0) {
        listenersMap.value.delete(eventName);
        const scope = scopesMap.value.get(eventName);
        if (scope) {
          scope.stop();
          scopesMap.value.delete(eventName);
        }
      }
    }
  };

  const createEventScope = (eventName) => {
    const scope = effectScope(true);
    scope.run(() => {
      const eventHandler = (event) => {
        const listeners = listenersMap.value.get(eventName);
        if (listeners && listeners.length > 0) {
          listeners[listeners.length - 1](event);
        }
      };

      onMounted(() => {
        document.addEventListener(eventName, eventHandler);
      })
      onScopeDispose(() => {
        document.removeEventListener(eventName, eventHandler);
      });
    });
    return scope;
  };

  return (eventName, listener) => {
    if (!listenersMap.value.has(eventName)) {
      listenersMap.value.set(eventName, []);
      scopesMap.value.set(eventName, createEventScope(eventName));
    }

    listenersMap.value.get(eventName).push(listener);

    onScopeDispose(() => {
      removeListener(eventName, listener);
    });
  };
})();

